var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from '@react-hooks-library/core';
import ym from 'react-yandex-metrika';
import { Header, Footer, OfficeView } from '../core';
import { useScrollWithShadow } from '../core/hooks/useScrollWithShadow';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import { schedulerActions, } from './store/schedulerStore';
const OfficeSelectorPanel = (props) => {
    const { officeStore } = props;
    const { offices } = officeStore;
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const { partnerOfficeId } = values;
    const isMobile = useMediaQuery('(max-width: 768px)');
    // const [minimizedOfficeSelector, setMinimizedOfficeSelector] = useState(false);
    const { scroller, positions } = useScrollWithShadow(offices.length);
    const clearReserve = () => __awaiter(void 0, void 0, void 0, function* () {
        if (values.appointmentJournalId) {
            try {
                yield schedulerActions.deleteReserve(values.appointmentJournalId);
                setValue('appointmentJournalId', null);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            }
            catch (error) {
                setValue('appointmentJournalId', null);
            }
        }
        setValue('date', null);
        setValue('time', null);
        setValue('localTime', null);
    });
    useEffect(() => {
        // Этот эффект вызывается при каждом входе в форму
        // Если надо будет сделать, чтоб при возврате в эту форму сразу НЕ затиралось
        // время резерва, то надо это событие перевести на подписку, как в фомре FillingClientDataPanel
        clearReserve();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnerOfficeId]);
    useEffect(() => {
        // Блок получение данных с везём колёса:
        if ((sessionStorage.getItem('partnerOfficeId')) && (!(values === null || values === void 0 ? void 0 : values.partnerOfficeId))) {
            const officeIdFormVezemKolesa = sessionStorage.getItem('partnerOfficeId').trim();
            const officeFromVezemKolesa = offices.find(v => v.id === officeIdFormVezemKolesa);
            if (officeFromVezemKolesa) {
                setValue('partnerOfficeId', officeFromVezemKolesa.id);
                if (!isMobile) {
                    setValue('partnerOfficeIdCentered', officeFromVezemKolesa.id);
                }
                setValue('office', officeFromVezemKolesa);
                ym('reachGoal', 'choose_office_send');
                stateAppActions.setAppStateOrder((values === null || values === void 0 ? void 0 : values.minimizedOfficeSelector) ? null : 3);
            }
        }
        // Конец блок получение данных с везём колёса:
    }, [offices]);
    return (_jsxs(_Fragment, { children: [!(values === null || values === void 0 ? void 0 : values.minimizedOfficeSelector)
                && (_jsx(Header, { returnNumber: 1, header: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043E\u0444\u0438\u0441', shadow: positions.top })), !(values === null || values === void 0 ? void 0 : values.minimizedOfficeSelector)
                && (_jsx("div", { className: 'padding_main_layer office-selector scroll_wrapper large_footer', ref: scroller, children: _jsx(Row, { className: 'pb-3', children: _jsx(Col, { children: offices.map(v => {
                                return (_jsxs("div", { children: [_jsx(OfficeView, { office: v, isSelectable: true, selected: (values === null || values === void 0 ? void 0 : values.partnerOfficeId) === v.id, onClick: () => {
                                                setValue('partnerOfficeId', v.id);
                                                if (!isMobile) {
                                                    setValue('partnerOfficeIdCentered', v.id);
                                                }
                                                setValue('office', v);
                                            }, showPrice: (values === null || values === void 0 ? void 0 : values.partnerOfficeId) === v.id, showBorderSelected: (values === null || values === void 0 ? void 0 : values.partnerOfficeId) === v.id, showPhotoPreview: true }), _jsx(Row, { className: 'delimiter', children: _jsx("div", {}) })] }, v.id));
                            }) }) }) })), _jsx(Footer, { label: (values === null || values === void 0 ? void 0 : values.minimizedOfficeSelector) ? 'Вернуться к списку офисов' : 'Записаться', nextNumber: (values === null || values === void 0 ? void 0 : values.minimizedOfficeSelector) ? null : 3, onClick: (values === null || values === void 0 ? void 0 : values.minimizedOfficeSelector) ? () => { setValue('minimizedOfficeSelector', false); } : null, disabled: !(values === null || values === void 0 ? void 0 : values.minimizedOfficeSelector) && !(values === null || values === void 0 ? void 0 : values.partnerOfficeId), variant: (values === null || values === void 0 ? void 0 : values.partnerOfficeId) ? 'primary' : null, onClickShowOffice: (isMobile && !(values === null || values === void 0 ? void 0 : values.minimizedOfficeSelector)) ? () => {
                    setValue('partnerOfficeIdCentered', values === null || values === void 0 ? void 0 : values.partnerOfficeId);
                    setValue('minimizedOfficeSelector', true);
                }
                    : null, shadow: positions.bottom })] }));
};
export default inject('referenceStore', 'officeStore')(observer(OfficeSelectorPanel));
