var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction } from 'mobx';
import orderController from './controller';
export const schedulerStore = observable({
    datesRequest: null,
    datesResponse: null,
    timesRequest: null,
    timesResponse: null,
    reserveTimesRequest: null,
    appointmentEditInterface: null,
    error: null,
});
export const schedulerActions = {
    getDates: (request) => __awaiter(void 0, void 0, void 0, function* () {
        runInAction(() => {
            schedulerStore.datesRequest = request;
        });
        const response = yield orderController.getDates(request);
        if (response.errorData) {
            schedulerActions.clearDates();
        }
        schedulerActions.handleError(response);
        if (!response.errorData) {
            schedulerStore.datesResponse = response.data;
        }
        return response.data;
    }),
    getTimes: (request) => __awaiter(void 0, void 0, void 0, function* () {
        runInAction(() => {
            schedulerStore.timesRequest = request;
        });
        const response = yield orderController.getTimes(request);
        if (response.errorData) {
            schedulerActions.clearTimes();
        }
        schedulerActions.handleError(response);
        if (!response.errorData) {
            schedulerStore.timesResponse = response.data;
        }
        return response.data;
    }),
    getReserve: (request_1, ...args_1) => __awaiter(void 0, [request_1, ...args_1], void 0, function* (request, token = null) {
        runInAction(() => {
            schedulerStore.reserveTimesRequest = request;
        });
        const response = yield orderController.getReserve(request, token);
        if (response.errorData) {
            schedulerStore.reserveTimesRequest = null;
        }
        schedulerActions.handleError(response);
        /* if (!response.errorData) {
          schedulerStore.timesResponse = response.data;
        } */
        return response.data;
    }),
    deleteReserve: (appointmentJournalId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield orderController.deleteReserve(appointmentJournalId);
        if (response.errorData) {
            schedulerStore.reserveTimesRequest = null;
        }
        schedulerActions.handleError(response);
    }),
    putAppointment: (request_1, appointmentJournalId_1, ...args_1) => __awaiter(void 0, [request_1, appointmentJournalId_1, ...args_1], void 0, function* (request, appointmentJournalId, token = null) {
        runInAction(() => {
            schedulerStore.appointmentEditInterface = request;
        });
        const response = yield orderController.putAppointment(request, appointmentJournalId, token);
        if (response.errorData) {
            schedulerStore.reserveTimesRequest = null;
        }
        schedulerActions.handleError(response);
        /* if (!response.errorData) {
          schedulerStore.timesResponse = response.data;
        } */
        return response.data;
    }),
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            schedulerStore.error = errorData;
            throw errorData;
        }
    }),
    clearDates: action(() => {
        schedulerStore.datesRequest = null;
        schedulerStore.datesResponse = null;
    }),
    clearTimes: action(() => {
        schedulerStore.timesRequest = null;
        schedulerStore.timesResponse = null;
    }),
    clearError: action(() => {
        schedulerStore.error = null;
    }),
};
