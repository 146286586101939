import axios from 'axios';
import merge from 'lodash/merge';
import APPConf from './config/common';

// const storage = process.env.NODE_ENV === 'production' ? sessionStorage : localStorage;

/* export const decodeToken = (token) => {
  try {
    if (token.split('.').length !== 3 || typeof token !== 'string') {
      return null;
    }

    const payload = token.split('.')[1];
    const base64 = payload.replace('-', '+').replace('_', '/');

    return JSON.parse(atob(base64));
  } catch (error) {
    return null;
  }
};

export const isTokenExpired = (token) => {
  const { exp: expirationUTCSeconds } = decodeToken(token) || {};

  if (!expirationUTCSeconds) {
    return true;
  }

  const expirationDate = new Date(0);

  expirationDate.setUTCSeconds(expirationUTCSeconds);

  return expirationDate.valueOf() < new Date().valueOf();
};

export const getTokenExpirationTimeout = (token) => {
  const { exp: expirationUTCSeconds } = decodeToken(token) || {};

  const oneWeekMs = 7 * 24 * 60 * 60 * 1000;
  const timeoutMs = expirationUTCSeconds
    ? (expirationUTCSeconds * 1000) - new Date().valueOf()
    : 0;

  // setTimeout принимает только 32битные числа,
  // потому ограничим таймаут истечения срока токена в 1 неделю.
  // в данном случае этого достаточно, так как предполагается,
  // что пользователь вряд ли будет держать открытой страницу в браузере более этого срока
  return timeoutMs < oneWeekMs ? timeoutMs : oneWeekMs;
};

export const saveCredentials = (credentials) => {
  storage.setItem('APP_Auth', JSON.stringify(credentials));
};

export const getCredentials = () => {
  return JSON.parse(storage.getItem('APP_Auth')) || {};
};

export const clearCredentials = () => {
  return storage.removeItem('APP_Auth');
}; */

export function createAxios() {
  if (window.axiosAPI) {
    return window.axiosAPI;
  }

  const ax = axios.create({
    baseURL: APPConf.serverAddress,
    timeout: 60000,
    validateStatus: status => {
      return status >= 200 && status <= 503; // ok
    },
  });

  ax.interceptors.response.use(response => { // соединение установлено, есть какой-то ответ бэка
    if (response.status === 430) { // logout
      // clearCredentials();

      return response;
    }

    // пока отобразим все 500е ошибы всплывающим сообщением
    // @todo legacy не уверен, что это вообще необходимо
    /* if (response.status >= 500) {
      toastr.error('Ошибка: ', `${ response.data.message } ( ${ response.status } )`);
    } */

    if (response.status >= 400 && response.status !== 401) {
      return { ...response, error: response.error || { response } };
    }

    return response;
  }, error => { // не удалось установить соединение
    if (axios.isCancel(error)) {
      return { error, connectError: true, data: {} };
    }

    // const msg = String.prototype.concat(APPConf.connectivityError, (process.env.DEBUG ? ' [createAxios]' : ''));

    // toastr.error('Ошибка: ', msg);

    if (error.code === 'ECONNABORTED') {
      return { error: { code: error.code, message: error.message }, connectError: true, data: {} };
    }

    return { error, connectError: true, data: {} };
  });

  window.axiosAPI = ax;

  return ax;
}

/**
 * Make request to API
 *
 * @param {String} method HTTP method - `get`, `post`, `put`, `patch` etc.
 * @param {String} url The server URL that will be used for the request
 * @param {Object|String} requestData The data to be sent as the request body.
 *                                    Only applicable for request methods 'PUT', 'POST', and 'PATCH'
 * @param {Object} requestConfig Axios request config. See {@link https://github.com/axios/axios#request-config}
 * @param okStatus
 * @return {Promise<any>}
 * @see https://github.com/axios/axios#axios-api
 */
export const makeRequest = (method, url, requestData = {}, requestConfig = {}, okStatus = 200, token = '') => {
  if (!window.axiosAPI) {
    createAxios();
  }

  const ax = window.axiosAPI;

  return new Promise((resolve, reject) => {
    // const auth = getCredentials();
    const headers = (requestData instanceof FormData)
      ? {
        'Content-Type': 'multipart/form-data',
      }
      : {
        'Content-Type': 'application/json',
      };

    // TODO - обсудить, может в localStorage или sessionStorage прятать токен ?
    if (token) { headers.Authorization = token; }

    const config = merge({
      method,
      headers,
      url,
      data: requestData,
    }, requestConfig);
     
    return ax(config).then(response => {
      const {
        connectError = false,
        status,
        data = {},
        error = {},
      } = response;

      const isError = status !== okStatus;

      let errorData = error?.error || error?.response?.data || data?.error || (error.toJSON && error.toJSON());

      // @todo убрать этот костыль после того, как апи стандартизирует формат ошибок
      if (typeof errorData === 'string') {
        errorData = { message: errorData };
      }

      // @todo временное? решение для преобразование API ошибки валидации
      // из формата { field: "fieldName", message: "validationErrorMessage" }
      // в формат понимаемый и используемый в любой валидации
      if (errorData && (errorData.field && errorData.message)) {
        errorData = { [errorData.field]: errorData.message };
      }

      resolve({
        status,
        headers: response.headers,
        connectError, // ошибка сети
        error: isError, // ошибка (статус ответа не тот который нужен)
        errorData, // содержимое ошибки
        data, // данные
      });
    }).catch((error) => {
      reject(error);

      // eslint-disable-next-line no-console
      console.error('axios request error', error);
    });
  });
};
