var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction } from 'mobx';
import isEqual from 'lodash/isEqual';
import orderController from './controller';
export const calculatePriceStore = observable({
    tireFittingPriceRequest: null,
    tireFittingPriceResponse: null,
    tireFittingPriceСache: [],
    tireStoragePriceRequest: null,
    tireStoragePriceResponse: null,
    tireStoragePriceCache: [],
    error: null,
});
export const calculatePriceActions = {
    getTireFittingPrice: (request) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // Излечение данных из кеша (отключи этот блок, если нужны данные с бэка напрямую)
        const cashedData = (_a = calculatePriceStore.tireFittingPriceСache.find(v => isEqual(request, v.request))) === null || _a === void 0 ? void 0 : _a.response;
        if (cashedData) {
            calculatePriceStore.tireFittingPriceResponse = cashedData;
            calculatePriceStore.tireFittingPriceRequest = request;
            return cashedData;
        }
        // Конец извлечения данных из кеша
        runInAction(() => {
            calculatePriceStore.tireFittingPriceRequest = request;
        });
        const response = yield orderController.getTireFittingPrice(request);
        if (response.errorData) {
            calculatePriceActions.clearTireFittingPrice();
        }
        calculatePriceActions.handleError(response);
        if (!response.errorData) {
            calculatePriceStore.tireFittingPriceResponse = response.data;
            calculatePriceStore.tireFittingPriceСache.push({
                request,
                response: response.data,
            });
        }
        return response.data;
    }),
    getTireStoragePrice: (request) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // Излечение данных из кеша (отключи этот блок, если нужны данные с бэка напрямую)
        const cashedData = (_a = calculatePriceStore.tireStoragePriceCache.find(v => isEqual(request, v.request))) === null || _a === void 0 ? void 0 : _a.response;
        if (cashedData) {
            calculatePriceStore.tireStoragePriceResponse = cashedData;
            calculatePriceStore.tireStoragePriceRequest = request;
            return cashedData;
        }
        // Конец извлечения данных из кеша
        runInAction(() => {
            calculatePriceStore.tireStoragePriceRequest = request;
        });
        const response = yield orderController.getTireStoragePrice(request);
        if (response.errorData) {
            calculatePriceActions.clearTireStoragePrice();
        }
        calculatePriceActions.handleError(response);
        if (!response.errorData) {
            calculatePriceStore.tireStoragePriceResponse = response.data;
            calculatePriceStore.tireStoragePriceCache.push({
                request,
                response: response.data,
            });
        }
        return response.data;
    }),
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            calculatePriceStore.error = errorData;
            throw errorData;
        }
    }),
    clearTireFittingPrice: action(() => {
        calculatePriceStore.tireFittingPriceRequest = null;
        calculatePriceStore.tireFittingPriceResponse = null;
    }),
    clearTireStoragePrice: action(() => {
        calculatePriceStore.tireStoragePriceRequest = null;
        calculatePriceStore.tireStoragePriceResponse = null;
    }),
    clearError: action(() => {
        calculatePriceStore.error = null;
    }),
};
