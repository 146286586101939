var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { toJS } from 'mobx';
import PriceBox from './PriceBox';
import { paramsTireStorageObj, paramsTireFittingObj, bonusPercentByType } from './PriceFunc';
import { calculatePriceActions, } from '../../order/store/calculatePriceStore';
const PriceComponent = (props) => {
    const { typePrice, calculatePriceStore, office, priceFrom, priceFromTireStoragePriceRequestInterface, priceFromTireFittingPriceRequestInterface, label, } = props;
    const { tireFittingPriceResponse, tireStoragePriceResponse, tireFittingPriceRequest, tireStoragePriceRequest, } = calculatePriceStore;
    const { getValues } = useFormContext(); // retrieve all hook methods
    const values = getValues();
    // const [tireFittingPriceResponse, setTireFittingPriceResponse] = useState<PriceResponseInterface>(null);
    // const [tireFittingPriceRequest, setTireFittingPriceRequest] = useState<TireFittingPriceRequestInterface>(null);
    // const [tireStoragePriceResponse, setTireStoragePriceResponse] = useState<PriceResponseInterface>(null);
    // const [tireStoragePriceRequest, setTireStoragePriceRequest] = useState<TireStoragePriceRequestInterface>(null);
    const paramsTireFitting = priceFrom
        ? priceFromTireFittingPriceRequestInterface
        : paramsTireFittingObj(values);
    const paramsTireStorage = priceFrom
        ? priceFromTireStoragePriceRequestInterface
        : paramsTireStorageObj(values);
    const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!(values === null || values === void 0 ? void 0 : values.partnerOfficeId) && !priceFrom)
            return;
        if (typePrice === 0) {
            const isNoEqualTireFittingRequest = !tireFittingPriceRequest || !isEqual(paramsTireFitting, toJS(calculatePriceStore === null || calculatePriceStore === void 0 ? void 0 : calculatePriceStore.tireFittingPriceRequest));
            if (isNoEqualTireFittingRequest) {
                yield calculatePriceActions.getTireFittingPrice(paramsTireFitting).then(() => {
                    // setTireFittingPriceResponse(res);
                    // setTireFittingPriceRequest(paramsTireFitting);
                });
            }
        }
        if (typePrice === 1) {
            const isNoEqualTireStorageRequest = !tireStoragePriceRequest || !isEqual(paramsTireStorage, tireStoragePriceRequest);
            if (isNoEqualTireStorageRequest) {
                yield calculatePriceActions.getTireStoragePrice(paramsTireStorage).then(() => {
                    // setTireStoragePriceResponse(res);
                    // setTireStoragePriceRequest(paramsTireStorage);
                });
            }
        }
    });
    useEffect(() => {
        if (((typePrice === 0) && ((values === null || values === void 0 ? void 0 : values.partnerOfficeId) !== (tireFittingPriceRequest === null || tireFittingPriceRequest === void 0 ? void 0 : tireFittingPriceRequest.partnerOfficeId)))
            || ((typePrice === 1) && ((values === null || values === void 0 ? void 0 : values.partnerOfficeId) !== (tireFittingPriceRequest === null || tireFittingPriceRequest === void 0 ? void 0 : tireFittingPriceRequest.partnerOfficeId)))) {
            loadData();
        }
    }, [values === null || values === void 0 ? void 0 : values.partnerOfficeId, values === null || values === void 0 ? void 0 : values.tireOperationRefValueId]);
    useEffect(() => {
        loadData();
    }, []);
    const designItems = [
        {
            label: 'Шиномонтаж',
            img: 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/tirefitting.png',
        },
        {
            label: 'Хранение',
            img: 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/storage.png',
        },
    ];
    const localPrice = (typePrice === 0
        ? tireFittingPriceResponse === null || tireFittingPriceResponse === void 0 ? void 0 : tireFittingPriceResponse.price
        : tireStoragePriceResponse === null || tireStoragePriceResponse === void 0 ? void 0 : tireStoragePriceResponse.price);
    const localPriceExtended = (priceFrom || (typePrice === 0 && !(values === null || values === void 0 ? void 0 : values.isExactPrice)))
        ? (`от ${localPrice === null || localPrice === void 0 ? void 0 : localPrice.toString()}`)
        : localPrice === null || localPrice === void 0 ? void 0 : localPrice.toString();
    /* const bonusPercent = ():number => {
      if (typePrice === 0) {
        return (office.bonusReceivingPercent ?? office.bonusReceivingPercentByService?.stationTireFitting ?? 0) / 100;
      }
      if (typePrice === 1) {
        return (office.bonusReceivingPercentByService?.stationTireStorage
          ?? officeActions.getPercentBonusForStorage(office?.id) // по временной схеме достаю % бонусов из officesGlobal
          ?? 0) / 100;
      }
      return 0;
    }; */
    if (localPrice) {
        return (_jsx(PriceBox, { imgUrl: designItems[typePrice].img, label: label !== null && label !== void 0 ? label : designItems[typePrice].label, priceText: localPriceExtended || '--', bonus: Math.ceil((localPrice !== null && localPrice !== void 0 ? localPrice : 0) * bonusPercentByType(typePrice, office)) }));
    }
    return (null);
};
export default inject('calculatePriceStore')(observer(PriceComponent));
