import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { Col, Button } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from '@react-hooks-library/core';
import ym from 'react-yandex-metrika';
import { MiniPinIcon, ClockIcon } from '../../../core/icons';
import { PriceFrom, OfficeRating, PriceComponentRow } from '../..';
import { CustomCard, Address, Schedule, Actions, } from './styles';
import { stateAppActions } from '../../../../lib/mobx/stateAppStore';
const OfficeCard = ({ officeStore, stateAppStore, className, office, office: { id, name, 
// photoPreviewUrl,
photoUrl, address, schedule, }, onClose, }) => {
    const { setValue, watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const offices = useMemo(() => toJS(officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices), [officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices]);
    const handleClick = useCallback((e) => {
        e.preventDefault();
    }, []);
    return (_jsxs(CustomCard, { className: className, onClick: handleClick, children: [_jsx(CustomCard.Img, { src: `${photoUrl}`, alt: '\u041D\u0435\u0442 \u0444\u043E\u0442\u043E\u0433\u0440\u0430\u0444\u0438\u0438', onError: (e) => {
                    e.currentTarget.src = 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/tireServiceBuildingWide.svg';
                } }), _jsxs(CustomCard.Body, { children: [_jsx(CustomCard.Title, { children: name }), _jsx(CustomCard.Subtitle, { children: _jsx(OfficeRating, {}) }), _jsxs(CustomCard.Text, { children: [_jsxs(Address, { children: [_jsx(MiniPinIcon, {}), _jsx("span", { title: address, children: address })] }), _jsxs(Schedule, { children: [_jsx(ClockIcon, {}), _jsx("span", { title: schedule, children: schedule })] })] })] }), _jsxs(Actions, { children: [((stateAppStore === null || stateAppStore === void 0 ? void 0 : stateAppStore.stateOrder) <= 1)
                        && _jsx(PriceFrom, { office: office }), ((stateAppStore === null || stateAppStore === void 0 ? void 0 : stateAppStore.stateOrder) > 1)
                        && _jsx(PriceComponentRow, { office: office })] }), (stateAppStore === null || stateAppStore === void 0 ? void 0 : stateAppStore.stateOrder) === 2
                && (_jsxs(Actions, { children: [isMobile
                            && (_jsx(Col, { md: 12, style: { marginBottom: '10px' }, children: _jsx(Button, { className: 'next-button', variant: 'outline-primary', onClick: () => {
                                        if (onClose) {
                                            onClose();
                                        }
                                        stateAppActions.setAppStateOrder(2);
                                        setValue('minimizedOfficeSelector', false);
                                    }, children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043A \u0441\u043F\u0438\u0441\u043A\u0443 \u043E\u0444\u0438\u0441\u043E\u0432" }) })), _jsx(Col, { md: 12, children: _jsx(Button, { className: 'next-button', variant: (values === null || values === void 0 ? void 0 : values.partnerOfficeId) ? 'primary' : 'outline-primary', onClick: () => {
                                    if (onClose) {
                                        setTimeout(() => {
                                            onClose();
                                        }, 100);
                                    }
                                    setValue('partnerOfficeId', id);
                                    setValue('office', offices.find((v) => v.id === id));
                                    setValue('partnerOfficeIdCentered', id);
                                    ym('reachGoal', 'choose_office_send');
                                    stateAppActions.setAppStateOrder(3);
                                }, children: "\u0417\u0430\u043F\u0438\u0441\u0430\u0442\u044C\u0441\u044F" }) })] }))] }));
};
export default inject('officeStore', 'stateAppStore')(observer(OfficeCard));
