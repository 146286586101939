var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable } from 'mobx';
import orderController from './controller';
export const clientStore = observable({
    error: null,
});
export const clientActions = {
    updateClient: (request_1, ...args_1) => __awaiter(void 0, [request_1, ...args_1], void 0, function* (request, token = null) {
        const response = yield orderController.updateClient(request, token);
        clientActions.handleError(response);
        return response.data;
    }),
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            clientStore.error = errorData;
            throw errorData;
        }
    }),
    clearError: action(() => {
        clientStore.error = null;
    }),
};
