import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { OfficeRating, RadioSelected, RadioUnselected, PriceComponentRow } from '..';
const OfficeView = (props) => {
    const { showBorder, showBorderSelected, showPrice, isSelectable, selected, onClick, office, showPhotoPreview, } = props;
    const officeInternalView = () => {
        return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        _jsxs("div", { className: 'officeView', onClick: () => { if (onClick) {
                onClick();
            } }, children: [isSelectable
                    && (_jsx("div", { className: 'selectSvg', children: selected ? _jsx(RadioSelected, {}) : _jsx(RadioUnselected, {}) })), _jsx("div", { className: 'leftDiv', children: _jsx("img", { src: `${showPhotoPreview ? office === null || office === void 0 ? void 0 : office.photoPreviewUrl : office === null || office === void 0 ? void 0 : office.photoUrl}`, alt: `${office === null || office === void 0 ? void 0 : office.name}`, onError: (e) => {
                            e.currentTarget.src = 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/tireServiceBuilding.svg';
                        } }) }), _jsx("div", { className: 'rightDiv', children: _jsxs(Row, { children: [_jsx(Col, { md: 12, className: 'avtoapp_color_font_16', children: office === null || office === void 0 ? void 0 : office.name }), _jsx(OfficeRating, {}), (office === null || office === void 0 ? void 0 : office.address)
                                && (_jsx(Col, { md: 12, className: 'font-14-400-gray-noSpacing fontHeight', children: office === null || office === void 0 ? void 0 : office.address })), (office === null || office === void 0 ? void 0 : office.schedule)
                                && (_jsx(Col, { md: 12, className: 'font-14-400-black-noSpacing fontHeight', children: office === null || office === void 0 ? void 0 : office.schedule }))] }) })] }));
    };
    return (_jsx("div", { children: (showBorder || showBorderSelected)
            ? (_jsx(Row, { className: 'pt-3', children: _jsx(Col, { children: _jsxs("div", { className: showBorderSelected ? 'bordered_block_selected' : 'bordered_block', children: [officeInternalView(), showPrice && _jsx(PriceComponentRow, { office: office })] }) }) }))
            : (_jsxs(Row, { className: 'pt-3', children: [officeInternalView(), showPrice && _jsx(PriceComponentRow, { office: office })] })) }));
};
export default OfficeView;
