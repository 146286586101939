import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { PriceComponent } from '..';
import { tireFittingRename } from './PriceFunc';
const PriceComponentRow = (props) => {
    const { office } = props;
    const { getValues } = useFormContext(); // retrieve all hook methods
    const values = getValues();
    const tireFitting = tireFittingRename(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId);
    return (_jsxs("div", { className: 'priceComponentsRow', children: [!!(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId)
                && (_jsx("div", { children: _jsx(PriceComponent, { typePrice: 0, office: office, label: tireFitting === null || tireFitting === void 0 ? void 0 : tireFitting.label }) })), !!(values === null || values === void 0 ? void 0 : values.hasTireStorage)
                && (_jsx("div", { children: _jsx(PriceComponent, { typePrice: 1, office: office }) }))] }));
};
export default inject('calculatePriceStore')(observer(PriceComponentRow));
