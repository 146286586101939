var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
// import { useMediaQuery } from '@react-hooks-library/core';
import { RefValueSelector, Header, Footer, BoxSelector, Subsection, Select, } from '../core';
import { officeActions } from '../map/store/officeStore';
import { useScrollWithShadow } from '../core/hooks/useScrollWithShadow';
import { hideTireCount } from './consts';
import { 
// ReferenceStore,
referenceActions, } from './store/referenceStore';
/* export interface ParametersPanelProps {
  referenceStore?: ReferenceStore,
} */
const ParametersPanel = () => {
    var _a;
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const [ref15, setRef15] = useState([]);
    const { scroller, positions } = useScrollWithShadow(ref15.length);
    const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
        const ref15Local = yield referenceActions.getRef(15);
        setRef15(ref15Local);
    });
    // const isMobile = useMediaQuery('(max-width: 768px)');
    // ref15console.log('ref15=',ref15);
    useEffect(() => {
        loadData();
    }, []);
    /* const options : OptionItemArrayType = [
      { value: 'yes', label: 'Да' },
      { value: 'no', label: 'Нет' },
      { value: 'dontknow', label: 'Не знаю' },
    ]; */
    /* const optionsStorage = [
      { value: 'yes', label: 'Да' },
      { value: 'no', label: 'Нет' },
    ]; */
    const optionsTireCount = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
    ];
    const optionsVehicleType = ref15
        ? ref15.filter(v => ((v.id === 43) || (v.id === 369))).map(v => {
            return {
                value: v.id,
                label: v.name,
                icon: v.string2,
            };
        })
        : [
            { value: 43, label: 'Легковой' },
            { value: 369, label: 'Внедорожный' },
        ];
    const optionsStorageDurationInMonths = [
        { value: 1, label: '1' },
        { value: 3, label: '3' },
        { value: 6, label: '6' },
    ];
    const loadOffices = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // setLoading(true);
            yield officeActions.getOfficesByParams({
                diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
                tireOperationRefValueId: values === null || values === void 0 ? void 0 : values.tireOperationRefValueId,
                vehicleTypeRefValueId: values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId,
                hasTireStorage: values === null || values === void 0 ? void 0 : values.hasTireStorage,
                runflatOnly: (values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue) === 'yes',
                showPhotoList: false,
            });
        }
        finally {
            // setLoading(false);
        }
    });
    useEffect(() => {
        // если на предыдущем этапе клиент указал хоть 1 услугу шиномонтажа, то тогда обновляем список офисов согласно параметрам
        if (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) {
            loadOffices();
        }
    }, [values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId, values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId, values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue]);
    useEffect(() => {
        var _a, _b;
        if (values === null || values === void 0 ? void 0 : values.partnerOfficeId) {
            setValue('partnerOfficeId', null);
            setValue('office', null);
        }
        // Блок получение данных с везём колёса:
        if (sessionStorage.getItem('diameterWheelRefValueId')) {
            setValue('diameterWheelRefValueId', parseInt((_a = sessionStorage.getItem('diameterWheelRefValueId')) !== null && _a !== void 0 ? _a : '32', 10));
        }
        if (sessionStorage.getItem('vehicleTypeRefValueId')) {
            setValue('vehicleTypeRefValueId', parseInt((_b = sessionStorage.getItem('vehicleTypeRefValueId')) !== null && _b !== void 0 ? _b : '43', 10));
        }
        if (sessionStorage.getItem('tireRunflat')) {
            setValue('tireRunflatOptionValue', (sessionStorage.getItem('tireRunflat').trim() === 'true') ? 'yes' : 'no');
        }
        // Конец блок получение данных с везём колёса:
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { returnNumber: 0, header: '\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B', shadow: positions.top }), _jsxs("div", { className: 'padding_main_layer scroll_wrapper', ref: scroller, children: [_jsx(BoxSelector, { name: 'vehicleTypeRefValueId', label: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0430\u0432\u0442\u043E\u043C\u043E\u0431\u0438\u043B\u044C', options: optionsVehicleType }), !!(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId)
                        && (_jsxs(_Fragment, { children: [_jsx(Subsection, { label: (_a = values === null || values === void 0 ? void 0 : values.tireOperationRefValueName) !== null && _a !== void 0 ? _a : 'Шиномонтаж', fontType: 16 }), !hideTireCount.includes(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId)
                                    && _jsx(BoxSelector, { name: 'tireCount', label: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043A\u043E\u043B\u0451\u0441', options: optionsTireCount }), _jsx(RefValueSelector, { refId: 10, label: '\u0414\u0438\u0430\u043C\u0435\u0442\u0440 \u043A\u043E\u043B\u0451\u0441', name: 'diameterWheelRefValueId' })] })), !!(values === null || values === void 0 ? void 0 : values.hasTireStorage)
                        && (_jsxs(_Fragment, { children: [_jsx(Subsection, { label: '\u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0448\u0438\u043D', fontType: 16 }), _jsx(Row, { children: _jsx(Col, { children: _jsx(RefValueSelector, { refId: 10, label: '\u0414\u0438\u0430\u043C\u0435\u0442\u0440 \u043A\u043E\u043B\u0451\u0441', name: 'diameterWheelRefValueId' }) }) }), _jsx(BoxSelector, { name: 'tireCountStorage', label: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043A\u043E\u043B\u0451\u0441', options: optionsTireCount }), _jsx(Row, { children: _jsx(Col, { children: _jsx(RefValueSelector, { refId: 79, label: '\u0427\u0442\u043E \u0445\u0440\u0430\u043D\u0438\u043C', name: 'storedObjectRefValueId' }) }) }), _jsx(Row, { children: _jsx(Col, { children: _jsx(Select, { options: optionsStorageDurationInMonths, label: '\u0421\u0440\u043E\u043A \u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044F', name: 'storageDurationInMonths' }) }) })] }))] }), _jsx(Footer, { label: '\u0414\u0430\u043B\u0435\u0435', nextNumber: 2, shadow: positions.bottom })] }));
};
export default inject('referenceStore', 'officeStore')(observer(ParametersPanel));
