import { makeRequest } from '../../../lib/api';
/* import {
  UniversalReferenceInterface,
} from '../api/references'; */
const OPEN_API_PATH = '/v1/open';
// const AUTH_PATH = '/v1/client/auth';
const AUTH_PATH = '/v3/client/auth';
const SCHEDULER_PATH = '/v2/client/scheduler';
const CLIENT_PATH = '/v1/client';
class orderController {
    constructor() {
        Object.defineProperty(this, "getUniversalReference", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (refId) => {
                return makeRequest('get', `${OPEN_API_PATH}/ref_value/${refId}`);
            }
        });
        Object.defineProperty(this, "getTireFittingPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${OPEN_API_PATH}/calculate/station_tire_fitting`, request);
            }
        });
        Object.defineProperty(this, "getTireStoragePrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${OPEN_API_PATH}/calculate/station_tire_storage`, request);
            }
        });
        Object.defineProperty(this, "getDates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('get', `${OPEN_API_PATH}/scheduler/free_dates?partnerOfficeId=${request.partnerOfficeId}&serviceId=${request.serviceId}`);
            }
        });
        Object.defineProperty(this, "getTimes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('get', `${OPEN_API_PATH}/scheduler/time_slots?partnerOfficeId=${request.partnerOfficeId}&serviceId=${request.serviceId}&date=${request.date}`);
            }
        });
        Object.defineProperty(this, "getReserve", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request, token = null) => {
                return makeRequest('post', `${OPEN_API_PATH}/scheduler/reserve`, request, {}, 200, token);
            }
        });
        Object.defineProperty(this, "deleteReserve", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (appointmentJournalId) => {
                return makeRequest('delete', `${OPEN_API_PATH}/scheduler/${appointmentJournalId}`);
            }
        });
        Object.defineProperty(this, "putAppointment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request, appointmentJournalId, token = null) => {
                return makeRequest('put', `${SCHEDULER_PATH}/${appointmentJournalId}`, request, {}, 200, token);
            }
        });
        Object.defineProperty(this, "sendSms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${AUTH_PATH}/captcha`, request);
            }
        });
        Object.defineProperty(this, "confirmSms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${AUTH_PATH}/confirm/captcha`, request);
            }
        });
        Object.defineProperty(this, "updateClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request, token = null) => {
                return makeRequest('put', `${CLIENT_PATH}/supplement`, request, {}, 200, token);
            }
        });
    }
}
export default new orderController();
