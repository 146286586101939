var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction } from 'mobx';
import isEqual from 'lodash/isEqual';
import officeController from './controller';
export const officeStore = observable({
    offices: [],
    // officesGlobal: [],
    officesCache: [],
    officesRequest: null,
    error: null,
});
export const officeActions = {
    getOffices: (request) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        officeActions.clearError();
        runInAction(() => {
            officeStore.officesRequest = request;
            officeStore.officesRequestByParams = null; // сбросим тут officesRequestByParams, чтоб знать, что выборка идёт по officesRequest
        });
        // извлечение данных из кеша. Если кеш офисов надо отключить вырежи этот блок кода.
        const cashedData = (_a = officeStore.officesCache.find((v) => isEqual(request, v.request))) === null || _a === void 0 ? void 0 : _a.offices;
        if (cashedData) {
            officeStore.offices = [...cashedData];
            return;
        }
        // конец работы с кешом кеша
        const response = yield officeController.getOffices(request);
        if (response.errorData) {
            officeActions.clearOffices();
        }
        officeActions.handleError(response);
        runInAction(() => {
            var _a;
            officeStore.offices = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.items;
            // officeStore.officesGlobal = [...officeStore.offices];
            officeStore.officesCache.push({
                request,
                offices: [...officeStore.offices],
            });
        });
    }),
    getOfficesByParams: (request) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        officeActions.clearError();
        runInAction(() => {
            officeStore.officesRequestByParams = request;
        });
        // извлечение данных из кеша. Если кеш офисов надо отключить вырежи этот блок кода.
        const cashedData = (_a = officeStore.officesCache.find((v) => isEqual(request, v.request))) === null || _a === void 0 ? void 0 : _a.offices;
        if (cashedData) {
            officeStore.offices = [...cashedData];
            return;
        }
        // конец работы с кешом кеша
        const response = yield officeController.getOfficesByParams(request);
        if (response.errorData) {
            officeActions.clearOffices();
        }
        officeActions.handleError(response);
        runInAction(() => {
            var _a;
            officeStore.offices = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.items;
            officeStore.officesCache.push({
                request,
                offices: [...officeStore.offices],
            });
        });
    }),
    /* getPercentBonusForStorage: (partnerOfficeId: string): number => {
      return officeStore.officesGlobal
        .find(v => v.id === partnerOfficeId)?.bonusReceivingPercentByService?.stationTireStorage ?? 0;
    }, */
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            officeStore.error = errorData;
            throw errorData;
        }
    }),
    clearOffices: action(() => {
        officeStore.offices = [];
        officeStore.officesRequest = null;
        officeStore.officesRequestByParams = null;
    }),
    clearError: action(() => {
        officeStore.error = null;
    }),
};
