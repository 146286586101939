import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useMediaQuery } from '@react-hooks-library/core';
import { useMemo, useCallback, useState, useEffect, } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useFormContext } from 'react-hook-form';
import { MobileModalForm } from '../core';
import { PlusIcon, MinusIcon, PositionArrowIcon } from '../core/icons';
import OfficeCard from '../core/visualComponents/OfficeCard';
// взято отсюда https://github.com/google-map-react/google-map-react
import GoogleMapReact from './source';
import { COORDINATES_MSK_CENTER, DEFAULT_ZOOM, DELTAS_FOR_ZOOM, MOBILE_DELTAS_FOR_ZOOM, MAP_OPTIONS, MAX_ZOOM, MIN_ZOOM, } from './consts';
import Marker from './Marker';
const Map = ({ officeStore }) => {
    var _a;
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const { partnerOfficeId, partnerOfficeIdCentered } = values; // partnerOfficeIdCentered - для мобильной версии для кнопку "Показать на карте нужно"
    const [center, setCenter] = useState(COORDINATES_MSK_CENTER);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [tempZoom, setTempZoom] = useState(DEFAULT_ZOOM);
    const [selected, setSelected] = useState(partnerOfficeId);
    const params = new URLSearchParams(window.location.search);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const deltasForZoom = useMemo(() => (isMobile ? MOBILE_DELTAS_FOR_ZOOM : DELTAS_FOR_ZOOM), [isMobile]);
    const offices = useMemo(() => { var _a; return (_a = toJS(officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices)) !== null && _a !== void 0 ? _a : []; }, [officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices]);
    const visibleOffices = useMemo(() => offices, [offices]);
    const handleCenter = useCallback((newCenter) => {
        setCenter(prevCenter => {
            // карта не реагирует на более мелкие изменения центра
            if (prevCenter.lat === newCenter.lat && prevCenter.lng === newCenter.lng) {
                return {
                    lat: newCenter.lat + 0.00001,
                    lng: newCenter.lng + 0.00001,
                };
            }
            return newCenter;
        });
    }, []);
    const handleMarkerClick = useCallback((key, otherProps) => {
        if (selected === key) {
            setSelected(undefined);
            // setValue('partnerOfficeId', undefined);
            // setValue('office', undefined);
            // setValue('partnerOfficeIdCentered', undefined);
            // stateAppActions.setAppStateOrder(2);
        }
        else {
            setSelected(key);
            // setValue('partnerOfficeId', key);
            // setValue('office', offices.find(({ id }) => id === selected));
            // setValue('partnerOfficeIdCentered', key);
            // stateAppActions.setAppStateOrder(3);
            if ((otherProps === null || otherProps === void 0 ? void 0 : otherProps.lat) && (otherProps === null || otherProps === void 0 ? void 0 : otherProps.lng)) {
                handleCenter({
                    lat: otherProps.lat + deltasForZoom[(tempZoom - MIN_ZOOM + 1)],
                    lng: otherProps.lng,
                });
            }
        }
    }, [selected, handleCenter, tempZoom, deltasForZoom /* , setValue, offices */]);
    const markers = useMemo(() => visibleOffices.reduce((pins, office) => {
        if (office.lat !== undefined && office.lng !== undefined) {
            pins.push(_jsx(Marker, { lat: office.lat, lng: office.lng, office: office, isMobile: isMobile, selected: office.id === selected, handleSelected: () => handleMarkerClick(office.id, { lat: office.lat, lng: office.lng }) }, office.id));
        }
        return pins;
    }, []), [visibleOffices, selected, handleMarkerClick, isMobile]);
    const selectedOffice = useMemo(() => visibleOffices.find(office => office.id === selected), [selected, visibleOffices]);
    const handlePanToCurrentLocation = useCallback(e => {
        e.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                handleCenter(pos);
            });
        }
    }, [handleCenter]);
    const handleZoomAnimationEnd = useCallback((newZoom) => {
        setTempZoom(newZoom);
    }, []);
    const handlePlusZoom = useCallback(e => {
        e.preventDefault();
        if (tempZoom < MAX_ZOOM) {
            setZoom(tempZoom + 1);
            setTempZoom(tempZoom + 1);
        }
    }, [tempZoom]);
    const handleMinusZoom = useCallback(e => {
        e.preventDefault();
        if (tempZoom > MIN_ZOOM) {
            setZoom(tempZoom - 1);
            setTempZoom(tempZoom - 1);
        }
    }, [tempZoom]);
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                handleCenter(pos);
            });
        }
    }, [handleCenter]);
    useEffect(() => {
        setSelected(partnerOfficeIdCentered);
        const partnerOffice = offices === null || offices === void 0 ? void 0 : offices.find(({ id }) => id === partnerOfficeIdCentered);
        if (partnerOffice && partnerOffice.lat && partnerOffice.lng) {
            handleCenter({ lat: partnerOffice.lat + deltasForZoom[(tempZoom - MIN_ZOOM + 1)], lng: partnerOffice.lng });
        }
    }, [partnerOfficeIdCentered, deltasForZoom, handleCenter, offices]);
    useEffect(() => {
        if (!selected) {
            const localPartnerOfficeId = params.get('partnerOfficeId');
            if (localPartnerOfficeId) {
                const officeFromUrl = visibleOffices.find(v => v.id === localPartnerOfficeId);
                if (officeFromUrl) {
                    handleMarkerClick(officeFromUrl.id, { lat: officeFromUrl.lat, lng: officeFromUrl.lng });
                }
            }
        }
    }, [visibleOffices, selected]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const hostname = ((_a = window.location.hostname) !== null && _a !== void 0 ? _a : '').trim();
    // console.log('hostname=',hostname);
    // TODO - тут надо поискать решение и ставить центр карты на геопозицию пользователя
    // если данных нет, или доступ запрещён - ставь центр Москвы
    return (
    // Important! Always set the container height explicitly
    _jsxs(_Fragment, { children: [_jsx("div", { className: 'map_box', children: (offices === null || offices === void 0 ? void 0 : offices.length) > 0 && (_jsx(GoogleMapReact, { options: MAP_OPTIONS, bootstrapURLKeys: {
                        // key: (hostname === 'localhost' || hostname === '127.0.0.1') ? '' : 'AIzaSyB_b6VlFqGyvzLutytAx63XQj1cUudqGls',
                        // key: '',
                        key: (hostname === '127.0.0.1'
                            // || hostname === 'localhost'
                            // || hostname === 'kolesa-widget.dev.avtoapp.online'
                            || hostname.indexOf('.loc') > 0
                        // || hostname.indexOf('v00') > 0
                        ) ? '' : process.env.GOOGLE_API_KEY,
                    }, center: center, zoom: zoom, onZoomAnimationEnd: handleZoomAnimationEnd, children: markers })) }), _jsxs("div", { className: 'map_ui', children: [_jsx("button", { type: 'button', className: 'map_ui_button map_ui_plus', onClick: handlePlusZoom, disabled: tempZoom >= MAX_ZOOM, children: _jsx(PlusIcon, {}) }), _jsx("button", { type: 'button', className: 'map_ui_button map_ui_minus', onClick: handleMinusZoom, disabled: tempZoom <= MIN_ZOOM, children: _jsx(MinusIcon, {}) }), _jsx("button", { type: 'button', className: 'map_ui_button', onClick: handlePanToCurrentLocation, children: _jsx(PositionArrowIcon, {}) })] }), isMobile && selectedOffice && (_jsx(MobileModalForm, { noHeader: true, showCloseButton: true, label: selectedOffice.name || '', onClose: () => {
                    setSelected(undefined);
                }, children: _jsx(OfficeCard, { office: selectedOffice, onClose: () => {
                        setSelected(undefined);
                    } }) }))] }));
};
export default inject('officeStore')(observer(Map));
