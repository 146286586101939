import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { format } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ym from 'react-yandex-metrika';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import { SuccessIcon } from '../core';
const SuccessfulSuccessContent = () => {
    var _a, _b, _c;
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    useEffect(() => {
        ym('reachGoal', 'order_success');
        setValue('appointmentJournalId', null);
    }, []);
    return (_jsxs("div", { className: 'success_form text-center', children: [_jsx("div", { className: 'picture', children: _jsx(SuccessIcon, {}) }), _jsx(Row, { className: 'text1', children: _jsx(Col, { className: 'avtoapp_color_font_34_400', children: "\u0417\u0430\u044F\u0432\u043A\u0430 \u043F\u0440\u0438\u043D\u044F\u0442\u0430" }) }), _jsxs(Row, { className: 'text2', children: [_jsxs(Col, { xs: 12, className: 'font-14-400-black-noSpacing', children: ["\u0416\u0434\u0451\u043C \u0412\u0430\u0441 ", format(values === null || values === void 0 ? void 0 : values.date, 'dd.MM.yyyy'), " \u0432 ", (_b = (_a = values === null || values === void 0 ? void 0 : values.localTime) === null || _a === void 0 ? void 0 : _a.split(':').slice(0, 2).join(':')) !== null && _b !== void 0 ? _b : '--.--.--'] }), _jsxs(Col, { xs: 12, className: 'font-14-400-black-noSpacing', children: ["\u043F\u043E \u0430\u0434\u0440\u0435\u0441\u0443 ", (_c = values === null || values === void 0 ? void 0 : values.office) === null || _c === void 0 ? void 0 : _c.address] })] }), _jsx(Row, { className: 'button_success', children: _jsxs(Col, { children: [_jsx(Button, { className: 'next-button bottom_props', variant: 'outline-primary', onClick: () => {
                                stateAppActions.setAppStateOrder(0);
                            }, children: "\u041E\u041A" }), ' '] }) })] }));
};
export default SuccessfulSuccessContent;
